import React, { useState, useEffect } from "react";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Contact() {
  const [scrolled, setScrolled] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    consultContent: "",
    companyName: "",
    fullName: "",
    email: "",
    details: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    if (scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (!formData.consultContent) errors.consultContent = true;
    if (!formData.companyName) errors.companyName = true;
    if (!formData.fullName) errors.fullName = true;
    if (!formData.email) errors.email = true;

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setFormData({
        consultContent: "",
        companyName: "",
        fullName: "",
        email: "",
        details: "",
      });

      alert("送信が成功しました！");
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
      handleNavigate();
    }
  };

  return (
    <div className="main-container">
      <div className={`header-container ${scrolled ? "scrolled" : ""}`}>
        <div className="header">
          <div className="header__inner__left">
            <img
              width="110"
              height="27"
              alt=""
              src="https://leapy.jp/wp/wp-content/themes/leapy22/images/common/logo-header.svg"
            />
          </div>
        </div>
      </div>
      <div className="contact_main_box">
        <div className="contact_box_left">
          <h2>
            <span className="en font-en">Contact</span>
            <span className="font_jp ">まずは相談する（無料）</span>
          </h2>
          <div id="parts_child_01-column_01">
            <div className="lps_parts--child inner flex flex-col1">
              <div>
                <h5 className="editor-ttl2  ">
                  具体的な「Webサイトの仕様」が決まっていない場合でのご相談も可能です。
                </h5>
                <p>
                  弊社提案内容を元に、他制作会社様にお問い合わせいただいても問題ありません。
                </p>
                <hr />
                <h5 className="editor-ttl2">
                  過去の支援実績（全国1,140社以上）
                </h5>
                <p>
                  <img
                    fetchpriority="high"
                    decoding="async"
                    className="wp-image-43174 size-full alignnone"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo.png"
                    alt="まずは相談する（無料）｜岐阜県でWebサイト制作なら株式会社リーピー"
                    width="810"
                    height="278"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo.png 810w, https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo-300x103.png 300w, https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo-780x268.png 780w, https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo-768x264.png 768w"
                    sizes="(max-width: 810px) 100vw, 810px"
                  />
                </p>
                <hr />
                <h5 className="editor-ttl2">
                  Web集客・採用強化のプロが、“地域No1になるためのWebサイト”をご提案します
                </h5>
                <p>
                  <img
                    decoding="async"
                    className="alignnone wp-image-43176 size-full"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/10/contact-staff.png"
                    alt="まずは相談する（無料）｜岐阜県でWebサイト制作なら株式会社リーピー"
                    width="648"
                    height="424"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/10/contact-staff.png 648w, https://leapy.jp/wp/wp-content/uploads/2024/10/contact-staff-300x196.png 300w"
                    sizes="(max-width: 648px) 100vw, 648px"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_box_right">
          <div className="box_right_innerbox">
            <div className="mce-content-body mgn-btm16">
              <h4>無料相談フォーム</h4>
              <div id="formy_form">
                <form
                  id="entry-form-id"
                  name="entry-form"
                  action="https://efo.entry-form.net/form/complete/c311e654b75d4800f5770ffd6c6058f73495558e/"
                  method="post"
                  data-parsley-validate=""
                >
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          ご相談内容<span className="requiredIcon">必須</span>
                        </th>
                        <td>
                          <ul>
                            <li>
                              <input
                                type="radio"
                                className="formy_radio formy_timer formy_radio_3362"
                                id="element_42_5ea79efb02294_0"
                                name="consultContent"
                                value="ホームページ制作について"
                                onChange={handleChange}
                              />
                              <label for="element_42_5ea79efb02294_0">
                                ホームページ制作について
                              </label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                className="formy_radio formy_timer formy_radio_3362"
                                id="element_42_5ea79efb02294_1"
                                name="consultContent"
                                value="運用代行について"
                                onChange={handleChange}
                              />
                              <label for="element_42_5ea79efb02294_1">
                                運用代行について
                              </label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                className="formy_radio formy_timer formy_radio_3362"
                                id="element_42_5ea79efb02294_2"
                                name="consultContent"
                                value="採用代行について"
                                onChange={handleChange}
                              />
                              <label for="element_42_5ea79efb02294_2">
                                採用代行について
                              </label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                className="formy_radio formy_timer formy_radio_3362"
                                id="element_42_5ea79efb02294_3"
                                name="consultContent"
                                value="DX支援について"
                                onChange={handleChange}
                              />
                              <label for="element_42_5ea79efb02294_3">
                                DX支援について
                              </label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                className="formy_radio formy_timer formy_radio_3362"
                                id="element_42_5ea79efb02294_4"
                                name="consultContent"
                                value="その他"
                                onChange={handleChange}
                              />
                              <label for="element_42_5ea79efb02294_4">
                                その他
                              </label>
                            </li>
                          </ul>
                          <br />
                          {formErrors.consultContent && (
                            <span style={{ color: "red" }}>
                              ご相談内容を選択してください
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label htmlFor="element_42_5a0e472ba77dd_ml">
                            会社名・屋号
                          </label>
                          <span className="requiredIcon">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="formy_text required formy_timer "
                            id="element_42_5a0e472ba77dd_cp"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            style={{
                              borderColor: formErrors.companyName
                                ? "red"
                                : "#ccc",
                            }}
                            placeholder="例）株式会社リーピー"
                            maxlength="255"
                            parsley-trigger="change keyup keypress focusin focusout"
                            data-required="true"
                          />
                          <br />
                          {formErrors.companyName && (
                            <span style={{ color: "red" }}>
                              会社名を入力してください
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label htmlFor="element_42_5a0e472ba77dd_ml">
                            氏名
                          </label>
                          <span className="requiredIcon">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="formy_text required formy_timer "
                            id="element_42_5a0e472ba77dd_cp"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            style={{
                              borderColor: formErrors.fullName ? "red" : "#ccc",
                            }}
                            placeholder="例）株式会社リーピー"
                            maxlength="255"
                            parsley-trigger="change keyup keypress focusin focusout"
                            data-required="true"
                          />
                          <br />
                          <span className="help_text">
                            ※フルネームでご記入ください。
                          </span>
                          <br />
                          {formErrors.fullName && (
                            <span style={{ color: "red" }}>
                              氏名を入力してください
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <th>
                          <label htmlFor="element_42_5a0e472ba77dd_ml">
                            メールアドレス
                          </label>
                          <span className="requiredIcon">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="formy_text required formy_timer "
                            id="element_42_5a0e472ba77dd_cp"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            style={{
                              borderColor: formErrors.email ? "red" : "#ccc",
                            }}
                            placeholder="例）株式会社リーピー"
                            maxlength="255"
                            parsley-trigger="change keyup keypress focusin focusout"
                            data-required="true"
                          />
                          <br />
                          <span className="help_text">
                            ※メールにて折り返しご連絡いたします。
                          </span>
                          <br />
                          {formErrors.email && (
                            <span style={{ color: "red" }}>
                              メールアドレスを入力してください
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <th>
                          <label htmlFor="element_42_5a0e472ba77dd_ml">
                            詳細
                          </label>
                        </th>
                        <td>
                          <textarea
                            className="formy_textarea  formy_timer"
                            id="element_42_5a0e472ba77dd_ms"
                            name="details"
                            placeholder="こちらにご相談内容をご記入ください。"
                            parsley-trigger="change keyup keypress"
                            value={formData.details}
                            onChange={handleChange}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <button
                  type="submit"
                  className="formy_submit"
                  onClick={handleSubmit}
                >
                  無料相談を申し込む
                </button>
              </div>
              <p className="recaptcha_policy mgn-btm80">
                This site is protected by reCAPTCHA and the Google
                <Link to="/privacy" onClick={handleNavigate}>
                  Privacy Policy
                </Link>
                apply.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
