import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/mainTwo.css";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="transparent-div">
          <div className="ai_absolute">
            <h2>
              Webサイトを起点に、
              <br />
              “地方の未来”をおもしろくする。
            </h2>
            <p>
              売上拡大や採用強化など、1,150社以上の支援実績で培ったナレッジとノウハウで、
              <br />
              “地域・業界No.1”を目指す地方企業のビジネスをデザインする「DX支援会社」です。
            </p>
            <Link className="ai_contact" to="/contact" onClick={handleNavigate}>
              まずは相談する
              <span
                className="free typesquare_option"
                style={{ width: "auto" }}
              >
                無料
              </span>
            </Link>
            <Link className="ai_more" to="/about" onClick={handleNavigate}>
              資料請求する
            </Link>
          </div>

          <div className="hero_post">
            <div className="home_pickupnews pos_rel">
              <h2>PICKUP NEWS</h2>
              <p>
                <span className="date font-en">2024.10.01</span>
                岐阜県のふるさと納税ポータルサイト「ぎふちょく」をリリースしました！
              </p>
            </div>
            <div className="home_pickupnews pos_rel">
              <h2 className="next_h2">
                <h3>
                  相談件数（累計）<span className="num font-en11">3,944</span>件
                </h3>
              </h2>
              <p>
                <div className="post flex">
                  <div className="post--date font-en11">2024.10.17</div>
                  <h2 className="post--ttl">
                    熊本県熊本市中央区の企業様より、資料請求をお申込みいただきました。
                  </h2>
                </div>
                <div className="post flex">
                  <div className="post--date font-en11">2024.10.16</div>
                  <h2 className="post--ttl">
                    大阪府茨木市の企業様より、地方採用ワークスのお申込みいただきました。
                  </h2>
                </div>
                <div className="post flex">
                  <div className="post--date font-en11">2024.10.16</div>
                  <h2 className="post--ttl">
                    大阪府茨木市の企業様より、地方採用ワークスのお申込みいただきました。
                  </h2>
                </div>
                <div className="post flex">
                  <div className="post--date font-en11">2024.10.11</div>
                  <h2 className="post--ttl">
                    静岡県伊東市の企業様より、お問い合わせいただきました。
                  </h2>
                </div>
              </p>
            </div>
          </div>
        </div>
        <section className="home_event pos_rel">
          <div className="inner inner-lg">
            <div className="home_event--ttlarea flex flex-a-end flex-sp-block gap48 gap-sp16 mgn-btm48">
              <h2 className="home_ttl-jp size-s anime active">
                <span className="en">Event</span>
                <span className="event_div">お悩み別相談会</span>
              </h2>
              <p className="home_event--txt font-jp-b anime typesquare_option active hard_p">
                「まだ漠然としているけど、誰かに意見を聞きたい…」
                <br className="sp-none" />
                そんなご状況下でのご相談も受け付けております。
              </p>
            </div>
            <div className="home_event--list">
              <div className="typesquare_option">
                <div className="option_img_div">
                  <img
                    alt=""
                    src="	https://leapy.jp/wp/wp-content/uploads/2024/07/advice-eyecatch01-768x430.jpg"
                  />
                </div>
                <div className="post--txtarea">
                  <h2>
                    <span className="arrow"></span>ホームページ相談会
                  </h2>
                </div>
              </div>
              <div className="typesquare_option">
                <div className="option_img_div">
                  <img
                    alt=""
                    src="	https://leapy.jp/wp/wp-content/uploads/2024/07/advice-eyecatch04-768x430.jpg"
                  />
                </div>
                <div className="post--txtarea">
                  <h2>
                    <span className="arrow"></span>DX（生成AI・RPA）相談会
                  </h2>
                </div>
              </div>
              <div className="typesquare_option">
                <div className="option_img_div">
                  <img
                    alt=""
                    src="https://leapy.jp/wp/wp-content/uploads/2024/07/advice-eyecatch06-768x430.jpg"
                  />
                </div>
                <div className="post--txtarea">
                  <h2>
                    <span className="arrow"></span>
                    【経営者限定】弊社代表の川口との1on1相談会
                  </h2>
                </div>
              </div>
              <div className="typesquare_option">
                <div className="option_img_div">
                  <img
                    alt=""
                    src="	https://leapy.jp/wp/wp-content/uploads/2024/07/advice-eyecatch02-768x430.jpg"
                  />
                </div>
                <div className="post--txtarea">
                  <h2>
                    <span className="arrow"></span>集客戦略相談会
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="home_interview">
          <h2>Interview</h2>
          <h6>お客様インタビュー</h6>
          <p>
            全国各地で地域・業界の“No.1”を目指すお客様に対して、当社制作サイトに対するご感想や
            <br className="sp-none" />
            リニューアル後の変化、また以降の事業展開で目指す未来についてお伺いしました。
          </p>
        </div>
        <div className="typesquare_option11">
          <div className="typesquare_option_inner">
            <p className="interview_parts_txt-lg font-en33">Pick Up</p>
            <div className="font_div_inner">
              <div className="interview_parts_img">
                <img
                  width="358"
                  height="358"
                  className="img-cover"
                  src="https://leapy.jp/wp/wp-content/uploads/2024/06/img01.jpg"
                  alt="dummy"
                />
              </div>
              <div className="pick_up_right">
                <p className="first_pickup">
                  制作後の反響についてお聞きしました。
                </p>
                <p className="sec_pickup">
                  リーピーさんは、私の理想とする会社をすでに実現されています。展開されているサービスはどれも信頼が置けて安心です。
                </p>
                <div className="third_pickup">
                  <p className="third_inner_first">
                    <span className="pickup_p_span_inner">
                      近江リース株式会社
                    </span>
                    <span className="flex third_first_span_all">
                      <span className="span_all_first">専務取締役</span>
                      <span className="span_all_sec">足立 咲 様</span>
                    </span>
                  </p>
                  <p className="third_inner_right">インタビューを見る</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid_two_all">
          <div className="grid_two_all_inner">
            <div className="pos_rel_inner">
              <div className="post_inner_img">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2024/10/img01-780x439.jpg"
                />
              </div>
              <div className="post_inner_txt">
                <h2>
                  DX＋マーケティングの代行により、
                  これからの事業成長が一層楽しみです。
                </h2>
                <p>
                  サン・ワード株式会社
                  <br />
                  山下 信彦 様
                </p>
              </div>
            </div>
            <div className="pos_rel_inner">
              <div className="post_inner_img">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2024/09/240213_470-780x520.jpg"
                />
              </div>
              <div className="post_inner_txt">
                <h2>
                  新規取引だけでなく、既存のお客様との新しいビジネスにつながりました。
                </h2>
                <p>
                  大垣機工株式会社
                  <br />
                  公文　良成 様
                </p>
              </div>
            </div>
            <div className="pos_rel_inner">
              <div className="post_inner_img">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2024/10/img07-780x439.jpg"
                />
              </div>
              <div className="post_inner_txt">
                <h2>
                  運用を通じてサポートしてくださるのは、
                  DX化を進める上で大きな支えになっています。
                </h2>
                <p>
                  近江リース株式会社
                  <br />
                  足立 咲 様
                </p>
              </div>
            </div>
            <div className="pos_rel_inner">
              <div className="post_inner_img">
                <img
                  alt=""
                  src="	https://leapy.jp/wp/wp-content/uploads/2019/03/iu_interview_main-780x439.jpg"
                />
              </div>
              <div className="post_inner_txt">
                <h2>
                  「福岡と岐阜の距離は一切感じない」。会社を成長させるために、Webへの投資は不可欠
                </h2>
                <p>
                  税理士法人アイユーコンサルティング
                  <br />
                  代表 岩永悠 様
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="one_img">
          <img
            alt=""
            src="https://leapy.jp/wp/wp-content/themes/leapy22/images/home/TOP_rogo.png"
          />
        </div>
        <div className="home_interview" style={{ marginTop: "10%" }}>
          <h2>Works</h2>
          <h6>Webサイト制作実績</h6>
          <p>
            リーピーでは、オリジナリティの高いデザインだけでなく
            <br className="sp-none" />
            SEO対策（検索上位化）・自社更新が可能な独自CMSの導入など、「デザイン+α」のWebサイトをお作りしています。
          </p>
        </div>
        <div className="work_div_all">
          <div className="home_event--list work_list">
            <div className="typesquare_option  work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2022/12/18rou_eyecatch_img-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>長良川温泉十八楼｜ブランドサイト・サービスサイト</h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>娯楽・レジャー</li>
                  <li>観光・レジャー</li> <li>東海地方</li>
                  <li>岐阜県</li>
                  <li>岐阜市</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option  work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>日の丸自動車株式会社｜コーポレートサイト</h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>その他サービス業</li>
                  <li>物流・運送</li> <li>東海地方</li>
                  <li>岐阜県</li>
                  <li>岐阜市</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option  work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>
                  【公式】「清流の国ぎふ総文2024（第48回全国高等学校総合文化祭／総文祭）」サイト｜岐阜県
                </h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>自治体・官公庁</li> <li>東海地方</li>
                  <li>岐阜県</li>
                  <li>岐阜市</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option  work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2022/11/all-different_eyecatch_img-2-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>
                  ALL
                  DIFFERENT株式会社｜採用・求人サイト（キャリア・中途採用向け）
                </h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>コンサルティング・調査</li>
                  <li>人材紹介・派遣</li> <li>関東地方</li>
                  <li>東京都</li>
                  <li>千代田区</li>
                </ul>
              </div>
            </div>

            <div className="typesquare_option work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="	https://leapy.jp/wp/wp-content/uploads/2024/01/3f3375c68bcde8970a0449785c6ef313-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>岐阜商工信用組合｜採用サイト</h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>金融・保険業</li> <li>岐阜県</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2020/02/kawaitosou-780x439.png"
                />
              </div>
              <div className="post--txtarea">
                <h3>河合塗装（株式会社エイトハンズ）｜コーポレートサイト</h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>建設・建築</li>
                  <li>塗装（外壁・屋根）</li> <li>東海地方</li>
                  <li>岐阜県</li>
                  <li>郡上市</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2022/11/infofarm_eyecatch_img2-1-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>株式会社インフォファーム｜コーポレートサイト</h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>建設・建築</li>
                  <li>リフォーム・リノベーション</li>
                  <li>IT・インターネット</li>
                  <li>システム開発</li>
                  <li>DX（デジタル化）</li> <li>東海地方</li>
                  <li>岐阜県</li>
                  <li>岐阜市</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2021/09/kanekokohyo-ec_eyecatch_img2-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>株式会社カネコ小兵製陶所｜ECサイト・オンラインショップ</h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>製造業</li>
                  <li>窯業（タイル・陶器など）</li>
                  <li>卸売・小売</li>
                  <li>小売業（BtoC）</li>
                  <li>農林・水産</li> <li>東海地方</li>
                  <li>岐阜県</li>
                  <li>土岐市</li>
                </ul>
              </div>
            </div>

            <div className="typesquare_option work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="	https://leapy.jp/wp/wp-content/uploads/2023/09/tochigi_eyecatch_img-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>ベリーマッチとちぎ｜移住支援ポータルサイト </h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>自治体・官公庁</li> <li>栃木県</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="	https://leapy.jp/wp/wp-content/uploads/2022/12/sketch-ibuki_eyecatch_img-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>社会福祉法人いぶき福祉会｜メディア・情報サイト </h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>医療・福祉</li>
                  <li>福祉・介護</li> <li>東海地方</li>
                  <li>岐阜県</li>
                  <li>岐阜市</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>近江リース株式会社｜ブランドサイト・コーポレートサイト </h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>インフラ関連</li>
                  <li>掃除・清掃</li>
                  <li>その他サービス業</li>
                  <li>レンタル・サブスク</li> <li>東海地方</li>
                  <li>岐阜県</li>
                  <li>大垣市</li>
                </ul>
              </div>
            </div>
            <div className="typesquare_option work_list_grid">
              <div className="option_img_div">
                <img
                  alt=""
                  src="	https://leapy.jp/wp/wp-content/uploads/2024/04/deto_eyecatch_img-780x431.jpg"
                />
              </div>
              <div className="post--txtarea">
                <h3>始道塾（株式会社Deto）｜ブランドサイト・サービスサイト </h3>
                <ul className="post--terms txt-xs flex flex-c-wrap">
                  <li>教育</li>
                  <li>学習塾</li> <li>全国</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="deco_txt"></div>
        <div className="home_interview" style={{ marginTop: "10%" }}>
          <h2>Service</h2>
          <h6>サービス紹介</h6>
        </div>
        <div className="work_service_div">
          <div className="work_service_inner">
            <div className="service_txtarea">
              <h3>
                “地域・業界No.1”の実現に向けた
                <br />
                強み抽出から運用・反響獲得までを支援。
              </h3>
              <p>
                企業の存在意義を言語化する「ブランディング支援」に始まり戦略的な「Webサイト制作」、
                <br className="sp-none" />
                さらにWeb集客を加速する「運用代行サービス」に至る、
                <br className="sp-none" />
                Web領域の上流から下流まで、お客様に寄り添って支援します。
              </p>
            </div>
            <div className="service_img">
              <img
                width="647"
                height="338"
                alt=""
                src="https://leapy.jp/wp/wp-content/themes/leapy22/images/home/service_img1.png"
              />
            </div>
          </div>
        </div>
        <ul className="home_service--flow inner inner-md flex flex-sp-block">
          <li>
            <h3 className="ttl">
              <span className="num font-en22">01.</span>
              <span className="txt typesquare_option">ブランディング支援</span>
            </h3>
            <div className="txtarea">
              <p className="mgn-btm16">
                ブランディングに必要な3つのフェーズを策定するワークショップを開催。ブランドガイドラインを作成します。
              </p>
              <p className="mgn-btm16 txt-ctr">
                <img
                  src="https://leapy.jp/wp/wp-content/themes/leapy22/images/home/service_img2.png"
                  alt=""
                />
              </p>
              <p className="btn-en txt-ctr">
                <a href="/" className=" typesquare_option">
                  詳しく見る
                </a>
              </p>
            </div>
          </li>
          <li>
            <h3 className="ttl">
              <span className="num font-en22">02.</span>
              <span className="txt typesquare_option">Webサイト制作</span>
            </h3>
            <div className="txtarea">
              <p className="mgn-btm16">
                高いデザイン性とSEO対策、どなたでも簡単にサイトを更新できる独自CMSなど「デザイン+α」の価値を提供。
              </p>
              <p className="mgn-btm16 txt-ctr">
                <img
                  src="	https://leapy.jp/wp/wp-content/themes/leapy22/images/home/service_img3.png"
                  alt=""
                />
              </p>
              <p className="btn-en txt-ctr">
                <a href="/" className=" typesquare_option">
                  詳しく見る
                </a>
              </p>
            </div>
          </li>
          <li>
            <h3 className="ttl">
              <span className="num font-en22">03.</span>
              <span className="txt typesquare_option">
                制作サイトの運用代行
              </span>
            </h3>
            <div className="txtarea">
              <p className="mgn-btm16">
                安定的な問い合わせ獲得など、Web集客・反響獲得を軌道に乗せるために、お客様に代わってサイトを運用します。
              </p>
              <p className="mgn-btm16 txt-ctr">
                <img
                  src="https://leapy.jp/wp/wp-content/themes/leapy22/images/home/service_img4.png"
                  alt=""
                />
              </p>
              <p className="btn-en txt-ctr">
                <a href="/" className=" typesquare_option">
                  詳しく見る
                </a>
              </p>
            </div>
          </li>
        </ul>
        <div className="home_interview" style={{ marginTop: "10%" }}>
          <h2>Service Details</h2>
          <h6>各サービスの詳細はこちら</h6>
        </div>
        <ul className="details flex flex-j-between flex-sp-block home_service--detail">
          <li>
            <a href="/website-production/corporate-site/">
              <span className="font-en33 en">CORPORATE SITE</span>
              <span className="jp typesquare_option">
                コーポレートサイト制作
              </span>
            </a>
          </li>
          <li>
            <a href="/website-production/recruitment-site/">
              <span className="font-en33 en">RECRUITMENT SITE</span>
              <span className="jp typesquare_option">採用サイト制作</span>
            </a>
          </li>
          <li>
            <a href="/website-production/e-commerce-site/">
              <span className="font-en33 en">E-COMMERCE SITE</span>
              <span className="jp typesquare_option">ECサイト制作</span>
            </a>
          </li>
          <li>
            <a href="/website-production/brand-site/">
              <span className="font-en33 en">BRAND SITE</span>
              <span className="jp typesquare_option">ブランドサイト制作</span>
            </a>
          </li>
        </ul>
        <div
          className="inner inner-xl"
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <section
            className="cta_area sm pos_rel contact"
            style={{ paddingBottom: "16%", borderRadius: "12.5vw 12.5vw 0 0" }}
          >
            <div className="inner inner-lg pos_rel">
              <div className="cta_area--txtarea">
                <div className="cta_field_ttl ttl-01 anime typesquare_option active">
                  <span className="en font-en">Contact</span>
                  <span className="jp typesquare_option">お問い合わせ</span>
                </div>
                <p className="cta_area--catch">
                  課題抽出やWebサイト制作の
                  <br />
                  目的整理からご相談ください
                </p>
                <div className="cta_area--lead mgn-btm40">
                  <p></p>
                  <p>
                    初めてのサイト制作で何をすればいいかお困りのお客様も歓迎。
                  </p>
                  <p>
                    現状の課題抽出やサイトの目的の整理、サイトコンセプトの策定からお任せください。もちろん、Web集客の戦略設計を具現化するサイト構成、デザイン、機能面までご提案します。
                  </p>
                  <p></p>
                </div>
                <p className="tel mgn-btm32">
                  058-215-0066
                  <small>24時間受付</small>
                </p>
                <ul className="cta_area--btn">
                  <li className="btn mgn-btm16">
                    <Link
                      to="/contact"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" typesquare_option"
                      onClick={handleNavigate}
                    >
                      まずは相談する
                      <span className="free typesquare_option">無料</span>
                    </Link>
                  </li>
                </ul>
                <ul className="cta_area--btn">
                  <li className="btn mgn-btm16">
                    <Link
                      to="/about"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" typesquare_option"
                      style={{ backgroundColor: "#fff", color: "#000" }}
                    >
                      資料請求する
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="cta_area--imgarea">
              <div className="cta_area--slide cta_area--slide1">
                <div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="岐阜県"
                      decoding="async"
                      fetchpriority="high"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="岐阜県"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="一般社団法人グローバル・メディアネットワーク"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="カンダまちおこし株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="日の丸自動車株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="近江リース株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="岐阜商工信用組合"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="株式会社アイコットリョーワ"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                </div>
                <div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="岐阜県"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="岐阜県"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="一般社団法人グローバル・メディアネットワーク"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="カンダまちおこし株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="日の丸自動車株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="近江リース株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="岐阜商工信用組合"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="株式会社アイコットリョーワ"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                </div>
              </div>
              <div className="cta_area--slide cta_area--slide2">
                <div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="大日コンサルタント株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-450x248.jpg 450w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-860x475.jpg 860w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="昭和コンクリート工業株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="医療法人和光会/社会福祉法人和光会"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="山田メディカルクリニック"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="川本鋼材株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="株式会社ササキ"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="株式会社TKS"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="社会福祉法人舟伏"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                </div>
                <div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="大日コンサルタント株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-450x248.jpg 450w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-860x475.jpg 860w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="昭和コンクリート工業株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="医療法人和光会/社会福祉法人和光会"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="山田メディカルクリニック"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="川本鋼材株式会社"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="株式会社ササキ"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="株式会社TKS"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                  <div className="cta_area--slide-item pos_rel">
                    <img
                      width="780"
                      height="431"
                      src="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg"
                      className="pos_ab img-cover"
                      alt="社会福祉法人舟伏"
                      decoding="async"
                      srcset="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img.jpg 1757w"
                      sizes="(max-width: 780px) 100vw, 780px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
