const newsData = [
  {
    id: 1,
    name: "Starboard Group Expands Exclusive SkInsight AI Skin Analysis Across Global Fleet, Redefining Onboard Beauty Retail",
    time: "Sep 30, 2024",
    strong: "New York, New York — September 30, 2024 —",
    linkA: "Perfect Corp.",
    firstDes: [
      "(NYSE: PERF), a global leader in augmented reality (AR) and artificial intelligence (AI) beauty and fashion tech, today announced a collaboration with Starboard Group, the world’s leading vacation retail curator. Continuing to pave the way for innovation as it rolls out its SkInsight experience, a cutting-edge interactive AI skin analysis, onboard 30 more ships this month. Developed in partnership with Perfect Corp., a pioneer in ‘Beautiful AI’, the SkInsight digital experience delivers detailed skin assessments to guests in seconds, along with uniquely personalized skincare regimens featuring products available for purchase onboard. Starboard debuted the SkInsight experience on Carnival Jubilee in December 2023 and will now offer the AI solution at retail stores on ships across Royal Caribbean International, Carnival Cruise Line, Virgin Voyages and TUI Mein Schiff fleets. ",
      "“We’re incredibly excited to expand this unique offering to so many more ships this year, allowing us to strategically leverage AI to enhance the retail experience for every skincare lover on board. It’s shopping, but smarter and even more fun!” said Lisa Bauer, Starboard Group President and CEO. “Our teams have collaborated closely with Perfect Corp to create a bespoke SkInsight app experience for Starboard, which underscores our commitment to innovation, seamlessly weaving engagement into the shopping experience.” ",
      "Beauty Solutions ‘For All,’ Enhanced By Technology",
      "Starboard’s 'Beauty for All' campaign empowers individuals to embrace their unique beauty with high quality products and solutions that are accessible to all and promote a vibrant and inclusive world through positive messaging. Through cutting-edge technology and immersive experiences, Starboard crafted a retail experience that’s as diverse and dynamic as its guests. SkInsight—a first-at-sea concept – is at the forefront of this revolution. It is also part of Starboard’ retailtainment concept which delivers immersive and engaging events and activations, as well as the creation of unique and compelling retail environments, digital experience and ecommerce.",
      "Powered by AI and augmented reality, SkInsight is a game-changer. In just seconds after snapping a photo, guests receive personalized skincare recommendations verified by dermatologists. The tool can address guests’ skincare concerns from wrinkles, to texture, and dark circles, or simple enhancements for glowing skin.",
      "SkInsight has become a cornerstone of Starboard’s “Beauty for All” initiative. Since its launch, the tool has delivered glowing results. SkInsight has not only increased the sales volume and guest spending by 20%, but it has also expanded the average transaction amount by 10%.",
      "Perfect Corp. developed a tailored solution for Starboard Group, specifically designed to address the challenges of operating at sea. With offline functionality and no reliance on internet connectivity, this solution ensures seamless performance in maritime environments, aligning with the unique operational demands of onboard retail.",
      "'We are thrilled to partner with Starboard to launch the SkInsight AI skin analysis experience at sea. AI-powered skin analysis has the potential to personalize skincare shopping like never before, offering tailored solutions for each individual,” said Perfect Corp. Founder and CEO Alice Chang, “By bringing this cutting-edge technology to Starboard’s travel retail environment at sea, we are taking the onboard beauty experience to new heights, ensuring that guests receive personalized care and advice while enjoying their journey.'",
      "With plans for continued expansion, Starboard's SkInsight symbolizes more than just a technological innovation— it’s a revolution in how it engages with beauty and a bold leap into the future of retail, setting new standards for inclusivity, personalization, and guest engagement. By seamlessly integrating AI and AR into the cruise experience, Starboard is transforming beauty shopping into an immersive, memorable adventure that resonates with every guest.",
      "About Starboard Group",
      "From its origins in 1958 charting new courses in the duty-free industry, Starboard has always been more than just a retailer—we're curators of vibrant experiences that enrich every vacation. As the world’s leading vacation retailer at sea, we've perfected the art of creating memorable shopping moments across more than 90 ships, partnering with 15 world-class cruise lines. Our luxury division is creating a unique boutique experience for luxury travelers by hand curating elevated retail collections and experiences for sophisticated travelers on ocean ships, yachts and adventure-based expedition vessels.  In 2024, as Starboard Group, we’re expanding our horizons even further. Now, with Starboard Cruise, Starboard Luxury, and Starboard Resort under our umbrella, we’re bringing our signature retail magic to land-based operations. This evolution positions us at the forefront of vacation retail, blending our rich maritime heritage with the exciting opportunities of resort-based shopping.",
      "Recognized time and again for our innovation and quality—garnering accolades such as DFNI-Frontier Americas Travel Retailer of the Year and Global Cruise Retailer of the Year—Starboard Group continues to set the standard in travel retail.  Our sister company, Onboard Media, crafts engaging multimedia experiences that drive brand awareness and boost revenue across the travel and hospitality sectors.",
      "Headquartered in Miami, with additional offices in Hong Kong, Shanghai, and Genoa, Italy, Starboard and Onboard Media are proud members of Global Travel Retail Holdings, LLC—a forward-thinking joint venture with LVMH and Gissy Investments, poised to lead the next wave of travel retail innovation.",
      "Starboard Group Media Contacts",
      "Lauren Dunaj | FINN Partners",
      "(415) 425-9345 | Lauren.Dunaj@finnpartners.com",
      "Paula Amador | Starboard Cruise Services",
      "(415) 425-9345 | Paula.Amador@starboardcruise.com",
      "About Perfect Corp.",
      "Perfect Corp. (NYSE: PERF) leverages ‘Beautiful AI’ innovations to make our world more beautiful. As a pioneer and leader in the space, Perfect Corp. works with over 650 partners around the globe to empower brands to embrace the digital-first world by transforming shopping journeys through digital tech innovations. Perfect Corp.’s suite of enterprise solutions delivers synergistic, technology-driven experiences that facilitate sustainable, ultra-personalized, and engaging shopping journeys through hyper-realistic virtual try-ons, AI-powered skin analyses, personalized product recommendation tools and many more Beautiful AI innovations.",
      "Perfect Corp. Press Contacts",
    ],

    images:
      "https://plugins-media.makeupar.com/smb/news/2024-09-30/1d51a272-7a64-4380-962a-9884271c679b.jpg",
  },
  {
    id: 2,
    name: "Perfect Corp. Introduces Auto Product Recommendation Tool for Skincare Brands and Retailers Powered by AI",
    time: "Sep 20, 2024",
    strong: "New York, New York — September 20, 2024 — ",
    linkA: "Perfect Corp.",
    firstDes: [
      "the leading AI and AR beauty and fashion technology provider and developer of ‘Beautiful AI’ solutions, today announces the launch of its new ‘AI Skincare Product Recommendation’ tool. This innovative feature, integrated into the backend console system, will utilize advancements in Perfect Corp.’s AI to simplify, streamline and accelerate the setup process for skincare brands utilizing Perfect Corp.’s AI Skin Analysis technology by automatically identifying which skincare concerns each of a brand’s products aim to target.",
      "Previously, skincare brands employing Perfect Corp.’s AI Skin Analysis services were required to manually define recommendation conditions and scenarios for each skincare product after creating their listings in the Perfect Console. This time-consuming process has now been automated with the ‘AI Skincare Product Recommendation’ tool. By leveraging product names, descriptions, images, and categories, the AI can intelligently identify which concerns should be matched to each product based on established logic and data, significantly reducing the manual configuration time. ",
      "Smart AI Automation Resolves Major Pain Points for Skincare Retailers and Brands",
      "Retailers and brands are also able to utilize the ‘AI Skincare Product Recommendation’ tool to generate these product category appointments in bulk, assigning skin concerns to dozens of products in their catalogues all once, something which would have previously been extremely time-consuming. All the console user has to do now is click a button, check the populated results, make any final changes, if necessary, and move on. Users are able to use the AI for up to 40 of these batches per week. ",
      "AI Understands Skincare Product Effectiveness for Accurate Setup",
      "The ‘AI Skincare Product Recommendation’ tool offers numerous benefits to skincare brands. Any console user, with or without product knowledge, can easily generate the correct skincare product recommendations. It simplifies the setup process, allowing brands to return to their core business quicker, rather than spending time on tedious technical configurations. Additionally, the tool accelerates time-to-market, enabling brands to launch their AI-powered skincare solutions much more quickly. By automating routine tasks, the AI frees up brand and retailer resources to concentrate on strategic initiatives and innovation.",
      "'We are thrilled to introduce the ‘AI Skincare Product Recommendation’ tool as a game-changer for skincare brands and retailers” said Perfect Corp. Founder and CEO, Alice Chang. “By automating the setup process, we are empowering brands and retailers to eliminate tedious and time-consuming setup steps completely. Our commitment to leveraging AI and AR technology is unwavering, and we believe this tool will further revolutionize the skincare shopping experience.'",
      "Perfect Corp. remains committed to pushing the boundaries of AI and AR technology. The development of the AI Skincare Recommendation tool is a testament to the company’s ongoing efforts to provide cutting-edge solutions that empower brands to enhance their customer experiences and drive growth.",
      "About Perfect Corp.",
      "Perfect Corp. (NYSE: PERF) leverages ‘Beautiful AI’ innovations to make our world more beautiful. As a pioneer and leader in the space, Perfect Corp. works with over 600 partners around the globe to empower brands to embrace the digital-first world by transforming shopping journeys through digital tech innovations. Perfect Corp.’s suite of enterprise solutions deliver synergistic, technology-driven experiences that facilitate sustainable, ultra-personalized, and engaging shopping journeys through hyper-realistic virtual try-ons, AI-powered skin analyses, personalized product recommendation tools and many more Beautiful AI innovations.",
    ],

    images:
      "https://plugins-media.makeupar.com/smb/news/2024-09-20/88947860-f362-4f2a-b9d7-ced2f84127f8.jpg",
  },
  {
    id: 3,
    name: "Perfect Corp. Partners with TIMEZ to Power a Smarter Way for Shopping Watches with Virtual Try-On Technology",
    time: "Sep 17, 2024",
    strong: "New York, New York — September 17, 2024 — ",
    linkA: "Perfect Corp.",
    firstDes: [
      " (NYSE: PERF), a global leader in augmented reality (AR) and artificial intelligence (AI) beauty and fashion tech, has announced a collaboration with TIMEZ, a next-generation watch aggregation platform. This partnership introduces a tailored digital solution for luxury watches on TIMEZ’s mobile application, integrating advanced 3D modeling, 3D rendering, and AR technologies to offer an enhanced digital experience.",
      "Users can access the “AR Try-On” feature from the app’s main interface. Each watch is displayed as a 3D model, allowing users to rotate and zoom in/out with a full 360° view. Perfect Corp.’s advanced AR virtual try-on functionality enables users to virtually “wear” the watch on their wrist. Its patented AgileHand™️ hand-tracking technology allows users to effortlessly rotate their wrists to view the watch from different angles. High-precision 3D rendering ensures that colors, textures, and lighting effects are accurately reproduced, showcasing the watch's quality and finish with perfect accuracy on screen. This highly interactive try-on experience simplifies the watch selection process, making it more intuitive and personalized for users.",
      "Additionally, users can utilize TIMEZ's proprietary AI watch recognition model to accurately identify specific watch models. By simply taking or uploading a photo within the app, the system can automatically recognize and match the watch to its brand and model within 0.8 seconds, providing not only detailed technical specifications but also market price data. Currently, the AI model supports identifying over 53,000 watches, offering users a quick and convenient search experience.",
      "Redefining the Future of Watch Shopping",
      "Since its inception, TIMEZ has been at the forefront of innovation, driven by the philosophy of “Technology Drives Innovation.” Powered by advanced AI technology and backed by extensive global watch datasets, TIMEZ is building a comprehensive platform that offers professional watch specification and market price data, intelligent authentication, 3D watch display, AR Try-on, and full lifecycle management for watches. Providing the users with a completely new experience.",
      "Recognizing the evolution within the watch market and changing purchasing behaviors, TIMEZ is committed to transforming the traditional watch authentication and trading models. By leveraging Perfect Corp.’s advanced technologies, TIMEZ has structured a vast array of branded watches into structured data, launching a market-responsive virtual try-on service within its app. This initiative aims to redefine the digital experience in the watch sector, providing comprehensive digital services for watch enthusiasts.",
      "Empowering Watch Brands with Virtual Try-Ons and Broadening Omnichannel Retail",
      "As an Industry leader in SaaS AI and AR solutions for beauty and fashion, Perfect Corp. remains dedicated to enhancing consumer shopping experiences through innovative digital technologies. The digital solution provided to TIMEZ promotes sustainable, personalized, and digital shopping experiences for TIMEZ’s mobile users, supporting it’s omnichannel growth.",
      "Alice Chang, Founder and CEO of Perfect Corp., stated, “We are pleased to work with TIMEZ in offering this innovative service, marking our debut in virtual watch try-ons via mobile app. We hope this collaboration will further enhance the digital shopping experience for watch enthusiasts and propel the watch industry’s future development. TIMEZ’s adoption of our technology to accurately and exquisitely reproduce luxury watches digitally is a testament to their trust in Perfect Corp.’s capabilities and our ability to meet the demands of the high-end watch market.”",
      "About TIMEZ",
      "A next-generation watch aggregation platform, powered by advanced AI technology, which collects enormous watch data including various watch brands, models, parameters, retail price, market price and so on, provides users with a professional and comprehensive watch database. TIMEZ not only provides the real time market prices of watches, but also assists users to find watches that satisfy their individual requirements.",
      "TIMEZ wishes to become the leader of watch database all over the world, and helps users to understand watches much easier than ever before. ",
      "About Perfect Corp.",
      "Perfect Corp. (NYSE: PERF) leverages ‘Beautiful AI’ innovations to make our world more beautiful. As a pioneer and leader in the space, Perfect Corp. works with over 650 partners around the globe to empower brands to embrace the digital-first world by transforming shopping journeys through digital tech innovations. Perfect Corp.’s suite of enterprise solutions delivers synergistic, technology-driven experiences that facilitate sustainable, ultra-personalized, and engaging shopping journeys through hyper-realistic virtual try-ons, AI-powered skin analyses, personalized product recommendation tools and many more Beautiful AI innovations.",
    ],
    images:
      "https://plugins-media.makeupar.com/smb/news/2024-09-17/258d1f08-9937-427f-acd6-8a39ee6d1be8.jpg",
  },

  {
    id: 4,
    name: "2023 Edison Awards™ for Innovative AgileHand™ Technology",
    time: "May 04, 2023",
    des: "The industry-leading AgileHand™ Technology celebrates a Bronze medal win at the 2023 Edison Best New Products Awards™ in the Personal Technology &amp; Gadgets category.",
    strong: "New York, NY – May 4th, 2023 - ",
    linkA: "Perfect Corp.",
    firstDes: [
      " (NYSE: PERF) celebrates their third win of the 2023 Edison Best New Products Awards™, which recognized their groundbreaking AgileHand™ Technology as the bronze winner for the Personal Technology & Gadgets Category for Augmented Reality. The patented breakthrough innovation is powered by AI, AR, and 3D hand-mapping technologies, which provide users with an interactive virtual try-on experience for products worn on the hands and wrists. This personalized digital try-on experience features hyper-realistic virtual try-on for watches, rings, bracelets, and nail colors, revolutionizing beauty and fashion retail for brands and consumers. ",
      "An Elevated Beauty and Fashion Retail Experience",
      "The Edison Best New Products Awards™, named after the American inventor Thomas Alva Edison, recognizes some of the world's most innovative products and business leaders. The prestigious awards honor excellence in new product and service development, marketing, design and innovation, and a body of peers, including top executives, academics, and innovation leaders from around the world, select the finalists. This marks the third time Perfect Corp. is recognized by The Edison Awards, with previous honors celebrating Perfect Corp.’s consumer apps, the YouCam Platform and Suite of Apps in 2019 and 2020. ",
      "Perfect Corp.’s AI Breakthrough Technology",
      "The AgileHandTM technology innovation marks an AI breakthrough that uses specialized 3D hand-mapping across hands and wrists and advanced Physically Based Rendering (PBR) for precision product renderings that take into account different materials and textures like metals, leather, wood, and silk. The virtual try-on technology also accounts for skin tones, textures, hands and finger sizes, and adaptive Enhanced Environmental Lighting, making for the most accurate and realistic digital try-ons imaginable. The technology introduces a seamless and effective way for consumers to virtually try-on watches, rings, bracelets and nail polishes, ensuring that 3D products are experienced with true-to-life finishes and effects, on par with a physical try-on.",
      "'We are honored that the judges of the Edison Awards recognized Perfect Corp.’s AgileHand™ Technology as a world-changing innovation,” shares Perfect Corp. Founder and CEO, Alice Chang. “This advanced AI solution elevates the beauty and fashion retail experience, transforming the way consumers shop for nail color, jewelry, and watches. We look forward to seeing how this technology innovation continues to enhance the consumer journey across beauty, fashion and beyond.'",
      "About Perfect Corp.",
      "Perfect Corp. is the leading SaaS AI and AR beauty and fashion tech solutions provider, dedicated to transforming shopping experiences through empowering brands to embrace the digital-first world. By partnering with the largest names in the industry, Perfect Corp.’s suite of enterprise solutions deliver synergistic, technology-driven experiences that facilitate sustainable, ultra-personalized, and engaging shopping journeys, as well as equipping brands with next generation of consumer goods. Perfect Corp. offers a complementary suite of mobile apps, including YouCam Makeup and YouCam Perfect, to provide a consumer platform to virtually try-on new products, perform skin diagnoses, edit photos, and share experiences with the YouCam Community. To learn more, please visit PerfectCorp.com. ",
      "About The Edison Awards: ",
      "Established in 1987, the Edison Awards is a program conducted by Edison Universe, a non-profit 501(c)(3) organization dedicated to fostering future innovators. The annual competition honors excellence in new product and service development, marketing, design and innovation. Past award recipients include Steve Jobs, Elon Musk, and leaders of global corporations such as Coca-Cola, Genentech, General Electric, General Motors, IBM and Campbell Soup Co. In 2022, the Edison Awards introduced the inaugural Lewis Latimer Fellowship program designed to celebrate, connect and bring together a community of innovative Black thought leaders. For more information, visit www.edisonawards.com. ",
    ],

    images:
      "https://plugins-media.makeupar.com/smb/awards/2023-12-29/d6abf4b1-7e4f-4bd5-a280-3be315ee52ed.jpg",
  },
  {
    id: 5,
    name: "Perfect Corp. Receives HR Asia Best Companies to Work for in Asia 2022 Award",
    time: "Sep 20, 2024",
    des: "Perfect Corp. recognized for its outstanding devotion to creating an exceptional workplace.",
    strong: "Taipei, Taiwan — August 22, 2022 — ",
    linkA: "Perfect Corp.",
    firstDes: [
      "the leading augmented reality (AR) and artificial intelligence (AI) beauty and fashion tech solutions provider today announced their success in HR Asia’s Best Companies to Work for in Asia 2022 Awards. The win solidifies the company’s position as a leading and forward-thinking enterprise both in Asia and around the world. ",
      "Identifying the Highest-Level of Dedication to the Creation of Exceptional Workplaces",
      "The HR Asia Best Companies to Work for in Asia Awards is an awards recognition program for organizations that have been identified by their employees as one of Asia’s employers of choice, and ascertains the best employers to work for. The award recognizes companies with the best HR practices, and which demonstrate high levels of employee engagement and excellent workplace cultures. ",
      "Recognizing Perfect Corp.'s Unparalleled Commitment to Inclusivity and Unity in the Workplace ",
      "Perfect Corp. prides itself on creating workplaces that foster creativity, reward passion, and give employees a space where they feel welcome, appreciated, and empowered to be the very best versions of themselves. This above-and-beyond approach comes from a place of incredible gratitude to employees for their unwavering commitment to the Perfect Corp. family. ",
      "The Perfect Corp. Family: A Place of Diversity and Inclusion",
      "'It is a great honor to be recognized by HR ASIA for this award” said Perfect Corp. Founder and CEO, Alice Chang, 'I would like to thank each and every one of our extraordinary employees for helping us every day to build this united family that fosters an environment of appreciation and improvement. We could not have come as far as we have without you. We look forward to continue to work together to create and maintain an inclusive and empowering environment for all employees to grow into the best possible versions of themselves'",
      "About Perfect Corp.",
      "Perfect Corp. is the leading SaaS AI and AR beauty and fashion tech solutions provider, dedicated to transforming shopping experiences through empowering brands to embrace the digital-first world. By partnering with the largest names in the industry, Perfect Corp.’s suite of enterprise solutions deliver synergistic, technology-driven experiences that facilitate sustainable, ultra-personalized, and engaging shopping journeys, as well as equipping brands with next generation of consumer goods. Perfect Corp. offers a complementary suite of mobile apps, including YouCam Makeup and YouCam Perfect, to provide a consumer platform to virtually try-on new products, perform skin diagnoses, edit photos, and share experiences with the YouCam Community. ",
    ],
    images:
      "https://plugins-media.makeupar.com/smb/awards/2022-08-23/108c5137-b883-4ac9-923a-1c8903cd6ffb.jpg",
  },
  {
    id: 6,
    name: "Perfect Corp.’s AgileHand Named Product of the Year in 2022 Business Intelligence Group Sales and Marketing Technology Awards",
    time: "Sep 17, 2024",
    des: "Perfect Corp.’s AgileHand Named Product of the Year in 2022 Business Intelligence Group Sales and Marketing Technology Awards",
    strong: "New York, New York—July 26th, 2022 — ",
    linkA: "Today Perfect Corp.’s AgileHandTM",
    firstDes: [
      "technology was awarded ‘Product of the Year’  by Business Intelligence Group in their annual 2022 Sales and Marketing Technology Awards, also known as the Sammy Awards. Perfect Corp. is delighted to have been recognized for its newest augmented reality (AR) and artificial intelligence (AI)-powered technology innovation - AgileHandTM, the groundbreaking technology for hands and wrists that maps a full range of gestures and movements to deliver true-to-life virtual try-ons for watches, rings, bracelets, and nail art. The technology empowers whole industries to introduce new and unique, interactive ways to connect with customers through virtual try-on shopping experiences. ",
      "Recognizing Breakthrough Innovations in AI and AR Technology ",
      "AgileHandTM technology marks a significant breakthrough in innovative development across AI and AR-powered virtual try-on for hands. The specialized 3D hand-mapping technology uses advanced Physically Based Rendering (PBR) technology to aid in precision renderings for 3D objects with true-to-life finishes and effects, mimicking light scattering of different materials such as sapphire watch faces, leather straps  or silver bands, to realistically recreate brands' physical products virtually. The virtual try-on experience also takes into account skin tones, textures, hand and finger sizes, with adaptive Enhanced Environmental Lighting making for more accurate and realistic digital try-ons. ",
      "Delivering Highly Advanced Beauty and Fashion Tech Solutions to Leading Brands",
      "'We are thrilled to be recognized by Business Intelligence Group for our advanced AgileHandTM technology which is helping to deliver immersive and impactful AI and AR-powered shopping experiences to increase purchaser confidence and customer satisfaction,” shares Perfect Corp. founder and CEO, Alice Chang. '",
      "The unique solution has been integrated by leading brands including Coty’s Sally Hansen, who implemented a first-to-market application of a virtual nail color try-on experience on their brand website. The real-time nail color try-ons launched on SallyHansen.com and invited customers to virtually try-on over 200 of the brands most popular nail color shades resulting in 100% increase in time spent on product display pages, 300% increase in number of shades viewed, and 120% increase in intent to purchase.  ",
      "'We are proud to reward and recognize Perfect Corp.’s AgileHandTM technology for their innovation and dedication to helping both the organization using their technology and the ultimate consumer,” said Maria Jimenez, Chief Nominations Officer, Business Intelligence Group. “It was clear to our judges that their efforts will improve how we all connect with the brands we love for years to come.'",
      "Expanding High-Tech Offerings to New Product Categories",
      "The launch of the new AgileHandTM Technology marks Perfect Corp.’s continued expansion of AI and AR fashion tech solutions, with the introduction of an impactful omni-channel solution for the luxury accessories market. For more information on Perfect Corp.’s AgileHandTM Technology solutions, visit https://www.perfectcorp.com/business/technologies/agile-hand-tracking.",
      "About Perfect Corp.",
      "Perfect Corp. is the leading SaaS AI and AR beauty and fashion tech solutions provider, dedicated to transforming shopping experiences through empowering brands to embrace the digital-first world. By partnering with the largest names in the industry, Perfect Corp.’s suite of enterprise solutions deliver synergistic, technology-driven experiences that facilitate sustainable, ultra-personalized, and engaging shopping journeys, as well as equipping brands with next generation of consumer goods. Perfect Corp. offers a complementary suite of mobile apps, including YouCam Makeup and YouCam Perfect, to provide a consumer platform to virtually try-on new products, perform skin diagnoses, edit photos, and share experiences with the YouCam Community. ",
      "About Business Intelligence Group",
      "The Business Intelligence Group was founded with the mission of recognizing true talent and superior performance in the business world. Unlike other industry award programs, business executives—those with experience and knowledge—judge the programs. The organization’s proprietary and unique scoring system selectively measures performance across multiple business domains and then rewards those companies whose achievements stand above those of their peers.",
    ],
    images:
      "https://plugins-media.makeupar.com/smb/awards/2022-07-27/7293b2c5-eb8c-45f0-86b8-283cbfd995b7.jpg",
  },
];

export default newsData;
