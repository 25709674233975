import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Component/Main";
import { CartProvider } from "./Component/CartContext";
import ProductDetail from "./Component/ProductDetail";
import Privacy from "./Component/Privacy";
import Particular from "./Component/Particular";
import Contact from "./Component/Contact";
import About from "./Component/About";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/particular" element={<Particular />} />
          <Route path="/about" element={<About />} />
          <Route path="/news/:id" element={<ProductDetail />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
