import React from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import newsData from "../Datas/newsData";

const ProductDetail = () => {
  const { id } = useParams();
  const product = newsData.find((item) => item.id === parseInt(id));

  if (!product) {
    return <div>商品が見つかりません</div>;
  }

  return (
    <>
      <Header />
      <div className="main-container">
        <div className="shopDiv">
          <div>
            <div className="news_top">
              <div className="news_top_left">
                <div class="blog-post__title__topic">News</div>
                <div class="blog-post__time">
                  <span>{product.time}</span>
                </div>
                <h1 class="blog-post__title blog-post__title-news">
                  {product.name}
                </h1>
              </div>
              <div className="news_top_right">
                <img alt="" src={product.images} />
              </div>
            </div>
            <div className="blog-post-body">
              <div className="blog-post-body__sharing">
                <div className="vertical media-container">
                  <img
                    width="24"
                    height="24"
                    alt="yt"
                    src="https://d3ss46vukfdtpo.cloudfront.net/static/media/YT_icon.81cb2f6b.svg"
                  />
                  <img
                    width="24"
                    height="24"
                    alt="linkedin_share"
                    src="https://d3ss46vukfdtpo.cloudfront.net/static/media/SNS_icon_linkedin.52126a3e.svg"
                  ></img>
                  <img
                    width="24"
                    height="24"
                    alt="email_share"
                    src="https://d3ss46vukfdtpo.cloudfront.net/static/media/btn_blog_mail.a9a33dd0.svg"
                  ></img>
                </div>
              </div>
              <div className="blog-post-body__main-content">
                <strong>{product.strong}</strong>{" "}
                <span
                  style={{
                    color: "rgb(0, 0, 255)",
                    textDecoration: "underline",
                  }}
                >
                  {product.linkA}
                </span>
                {product.firstDes.map((des, index) => (
                  <p key={index}>{des}</p>
                ))}
              </div>
              <div class="blog-post-body__popular"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetail;
