import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="footer_all_inner">
          <div className="inner mgn-btm80">
            <h2 className="footer--logo txt-ctr mgn-btm80">
              <img
                width="195"
                height="257"
                alt=""
                src="https://leapy.jp/wp/wp-content/themes/leapy22/images/common/logo-footer.svg"
              />
            </h2>
            <p className="footer--cacth txt-ctr mgn-btm80">
              地方の未来をおもしろくする。
            </p>
            <div className="fnav flex flex-j-betweena flex-sp-block mgn-btm80">
              <ul>
                <li>
                  <Link to="/privacy" onClick={toTop}>
                    プライバシーポリシー
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toTop}>
                    お問い合わせ
                  </Link>
                </li>
                <li>
                  <Link to="/particular" onClick={toTop}>
                    情報セキュリティ方針
                  </Link>
                </li>
              </ul>
            </div>

            <div id="footer_client">
              <h3>
                <span className="font-en44">Client</span>
                <font _mstmutation="1">実績一覧</font>
              </h3>
            </div>

            <div className="footer_license">
              <ul className="footer_license--list flex flex-j-ctr">
                <li>
                  <img
                    alt=""
                    src="https://leapy.jp/wp/wp-content/uploads/2023/06/isms-1.png"
                  />
                  <small>
                    株式会社リーピーは、情報セキュリティマネジメントシステム（ISMS）の国内規格「JIS
                    Q 27001：2014（ISO/IEC
                    27001：2013）」の認証を取得しています。（本社のみ）
                  </small>
                </li>
                <li>
                  <img
                    width="88"
                    height="88"
                    alt=""
                    src="https://leapy.jp/wp/wp-content/themes/leapy22/images/footer/logo-security_action.png"
                  />
                </li>

                <li>
                  <img
                    width="249"
                    height="88"
                    alt=""
                    src="	https://leapy.jp/wp/wp-content/themes/leapy22/images/footer/logo-gold_partner.jpg"
                  />
                </li>

                <li>
                  <img
                    width="170"
                    height="121"
                    alt=""
                    src="https://leapy.jp/wp/wp-content/themes/leapy22/images/footer/logo-keizokuryoku.jpg"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_service bg-sub">
            <ul className="flex flex-a-ctr flex-j-ctr inner mgn-btm48">
              <li>
                <img
                  src="	https://leapy.jp/wp/wp-content/themes/leapy22/images/footer/logo-works.png"
                  width="350"
                  height="50"
                  alt="地方採用ワークス"
                  loading="lazy"
                  _mstalt="19746363"
                />
              </li>
              <li>
                <img
                  src="	https://leapy.jp/wp/wp-content/themes/leapy22/images/footer/logo_leap_career.png"
                  width="350"
                  height="53"
                  alt="リープ・キャリア"
                  loading="lazy"
                  _mstalt="13652964"
                />
              </li>
              <li>
                <img
                  src="https://leapy.jp/wp/wp-content/themes/leapy22/images/footer/logo_pace.png"
                  width="158"
                  height="53"
                  alt="Pace（ペース）"
                  loading="lazy"
                  _mstalt="28449902"
                />
              </li>
              <li>
                <img
                  src="https://leapy.jp/wp/wp-content/themes/leapy22/images/footer/logo_gifu42.svg"
                  width="158"
                  height="53"
                  alt="gifu42"
                  loading="lazy"
                  _mstalt="62673"
                />
              </li>
            </ul>
            <div className="footer--mini_menu txt-sm txt-ctr">
              岐阜県を応援するクラウドファンディング型ふるさと納税【ぎふちょく】
            </div>
          </div>
          <p className="copyright bg-bk txt-ctr txt-wh txt-sm font-en55">
            © Leapy Inc.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
