import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_all_box">
        <div className="privacy_box_inner">
          <hgroup className="privacy_first_box">
            <p lang="en">Privacy policy</p>
            <h1>プライバシーポリシー</h1>
          </hgroup>
          <div className="privacy_sec_box">
            <div className="contact_about">
              <Link
                className="privacy_contact"
                to="/contact"
                onClick={handleNavigate}
              >
                まずは相談する
                <span
                  className="free typesquare_option"
                  style={{ width: "auto" }}
                >
                  無料
                </span>
              </Link>
              <Link
                className="privacy_more"
                to="/about"
                onClick={handleNavigate}
              >
                資料請求する
              </Link>
            </div>
            <div id="breadcrumbs" className="breadcrumbs txt-sm">
              <span>
                <span>
                  <Link to="/">HOME</Link>
                </span>
                <span className="breadcrumb_last" aria-current="page">
                  プライバシーポリシー
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="privacy_main_container">
        <div id="section-01" className="section_pdg ">
          <h2>
            個人情報保護方針及び
            <br />
            個人情報の取扱いについて
          </h2>
        </div>

        <div className="mce-content-body">
          <div className="mce_inner">
            株式会社リーピー（以下「当社」）は、Webサイト制作事業、アウトソーシング事業（Web反響獲得・採用強化・業務効率化を目的とした各種代行サービス）、ブランディング支援事業、人材紹介事業、Webサービス事業等を中心とした事業活動を行っており、事業において個人情報を取り扱う企業として、
            個人情報を大切に保護することを当社の重要な社会的使命と認識し、当社が業務を通じて取り扱う個人情報に関して、以下のとおりプライバシーポリシーを定め運用いたします。
          </div>
        </div>

        <div className="mce-content-body">
          <div className="first_privacy">
            <h4>1.個人情報取扱いに関する基本方針</h4>
            <ol>
              <li>
                個人情報取得にあたっては、利用目的をできる限り特定し、その目的の達成に必要な範囲において行います。
              </li>
              <li>
                <font _mstmutation="1">
                  ご本人から直接、書面にて個人情報を取得する場合には、下記をお知らせしたうえで、必要な範囲の個人情報を取得します。
                </font>
                <ul>
                  <li>当社名及び連絡先</li>
                  <li>個人情報の利用目的</li>
                </ul>
              </li>
              <li>
                個人情報の利用は、本人から同意を得た利用目的の範囲内で行い、その範囲を超えて利用を行わないため、必要な対策を講じる手順を確立し、実施いたします。
              </li>
              <li>
                お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底などの必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
              </li>
              <li>
                個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じます。
              </li>
              <li>
                個人情報の処理を外部へ委託する場合、契約により、漏洩や第三者への提供を行わない等を義務づけ、委託先に対する適切な管理を実施いたします。
              </li>
              <li>
                保有する個人情報に関するご要望・ご相談は、当社問合せ窓口までご連絡いただくことで、これに対応いたします。
              </li>
              <li>
                保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、プライバシーポリシーの内容を適宜見直し、その改善に努めます。
              </li>
              <li>
                当社は、プライバシーポリシーを随時更新します。プライバシーポリシーの重要な変更は、最新のプライバシーポリシーとともに当社のWebサイトに掲載します。
              </li>
            </ol>
          </div>
          <div className="sec_privacy">
            <h4>2.取得情報及び取得方法</h4>
            <ol>
              <li>
                当社が取得する情報は、その取得方法に応じて、次のとおりとします。
              </li>
            </ol>
            <table className="table2 table-sp-block">
              <tbody>
                <tr>
                  <th width="30%">お客さまから直接取得する情報</th>
                  <td>
                    氏名、年齢又は生年月日、性別、職業・職歴・学歴、住所、電話番号、メールアドレス、写真・動画
                  </td>
                </tr>
                <tr>
                  <th width="30%">
                    お客さまが当社サービスに
                    <br />
                    アクセスする際に取得する情報
                  </th>
                  <td>
                    端末の種類、端末識別子、ブラウザの種類、IPアドレス、Cookie、サーバーのアクセスログ等の利用ログ情報
                  </td>
                </tr>
                <tr>
                  <th width="30%">
                    契約等に基づく外部の委託先
                    <br />
                    又は提携先から取得する情報
                  </th>
                  <td>
                    受託業務の履行のために必要な範囲内の情報であり、利用の同意を受けたうえで取得した情報
                  </td>
                </tr>
                <tr>
                  <th width="30%">
                    外部サービスとの連携により
                    <br />
                    取得する情報
                  </th>
                  <td>
                    外部サービスでお客さまが利用するID、その他外部サービスのプライバシー設定によりお客さまが連携先に開示を認めた情報
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="sec_privacy">
            <h4>3.個人情報の取扱い及び利用目的</h4>
            <ol>
              <li>
                お客さまから取得した個人情報は、次の目的の範囲内で利用いたします。
                <br />
                また、当社の複数事業にわたるサービス提供や関連するプロモーション活動のために、事業間で個人情報を共有する場合があります。ただし、利用目的が、次の目的の範囲に含まれない場合、直接取得した場合はご本人から同意を得たうえで、それ以外の手段で取得した場合には、その目的を公表したうえで、利用いたします。
              </li>
            </ol>

            <table className="table2 table-sp-block">
              <tbody>
                <tr>
                  <th style={{ width: "50%", textAlign: "center" }}>
                    対象の個人情報
                  </th>
                  <th style={{ width: "50%", textAlign: "center" }}>
                    利用目的
                  </th>
                </tr>
                <tr>
                  <th>Webサイト制作に関わる個人情報</th>
                  <td>
                    Webサイト制作における管理・更新のために利用します。また、当社の他のサービスをご案内するために利用します。
                  </td>
                </tr>
                <tr>
                  <th>
                    Web反響獲得を目的とした
                    <br />
                    マーケティングアウトソーシングに関わる個人情報
                  </th>
                  <td>
                    マーケティング代行業務の遂行のために利用します。また、当社の他のサービスをご案内するために利用します。
                  </td>
                </tr>
                <tr>
                  <th>
                    採用強化を目的とした
                    <br />
                    採用業務アウトソーシングに関わる個人情報
                  </th>
                  <td>
                    採用業務代行業務の遂行のために利用します。また、当社の他のサービスをご案内するために利用します。
                  </td>
                </tr>
                <tr>
                  <th>
                    業務効率化を目的とした
                    <br />
                    DX導入アウトソーシングに関わる個人情報
                  </th>
                  <td>
                    DX導入代行業務の遂行のために利用します。また、当社の他のサービスをご案内するために利用します。
                  </td>
                </tr>
                <tr>
                  <th>人材紹介事業に関わる個人情報</th>
                  <td>
                    人材紹介事業の運営・管理及び求人情報の提供や採用選考、活動支援、問合せ対応等を含めた登録者の管理に利用します。また、当社の他のサービスをご案内するために利用します。
                  </td>
                </tr>
                <tr>
                  <th>上記以外の受託業務に関わる個人情報</th>
                  <td>
                    当該受託業務の履行のために利用します。また、当社の他のサービスをご案内するために利用します。
                  </td>
                </tr>
                <tr>
                  <th>Webサービスに関わる個人情報</th>
                  <td>アカウント発行や管理のために利用します。</td>
                </tr>
                <tr>
                  <th>求人媒体作成に関わる個人情報</th>
                  <td>企業の紹介のために利用します。</td>
                </tr>
                <tr>
                  <th>お取引先に関する個人情報</th>
                  <td>
                    当社事業に関する情報提供や営業活動、アンケート依頼、取引先管理に利用します。
                  </td>
                </tr>
                <tr>
                  <th>
                    当社事業及び企業情報に関するお問い合わせ、資料請求、セミナー・展示会等のお申し込み、及び電話やEメール、ダイレクトメール、訪問、名刺交換といった営業活動を通じて得た個人情報
                  </th>
                  <td>
                    当社事業に関する情報提供や営業活動、アンケート依頼、取引先管理に利用します。
                  </td>
                </tr>
                <tr>
                  <th>従業員・退職者に関する個人情報</th>
                  <td>雇用及び人事管理に利用します。</td>
                </tr>
                <tr>
                  <th>採用応募者に関する個人情報</th>
                  <td>
                    選考および採否に関する連絡に利用します。また、当社の他のサービスをご案内するために利用します。
                  </td>
                </tr>
                <tr>
                  <th>メールマガジン発行に関する個人情報</th>
                  <td>メールマガジン登録者の管理に利用します。</td>
                </tr>
                <tr>
                  <th>当社が明示した利用目的の同意のうえで 取得した個人情報</th>
                  <td>その利用目的のために利用します。</td>
                </tr>
              </tbody>
            </table>
            <ol start="2">
              <li>
                当社は広告の効果測定のため、第三者の運営するツールから当社サイトに訪れる前にクリックされている広告の情報（クリック日や広告掲載サイトなど）を取得し、お申込み情報と照合する場合がございます。
              </li>
              <li>
                電話もしくはオンライン会議システム等によるお客さまからのご連絡や当社からご連絡させていただく場合に、内容の正確な記録及び再確認並びに応対の品質向上への活用として、通話内容を録音・録画をさせていただく場合がございます。
              </li>
            </ol>
          </div>

          <div className="sec_privacy">
            <h4>4.個人情報の第三者提供</h4>
            <ol>
              <li>
                当社は、次に掲げる場合を除いて、事前にお客さまの同意を得ることなく個人情報を第三者に提供いたしません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
                <ul>
                  <li>
                    人命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。
                  </li>
                  <li>
                    国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
                  </li>
                </ul>
              </li>
              <li>
                前項の定めにかかわらず、次に掲げる場合には当該情報の提供先は第三者に該当しないものとします。
                <ul>
                  <li>
                    合併その他の事由による事業の承継に伴って個人情報が提供される場合
                  </li>
                  <li>
                    個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめ本人に通知し、又は本人が容易に知り得る状態に置いた場合
                  </li>
                  <li>
                    当社が利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="sec_privacy">
            <h4>5.個人情報の共同利用</h4>
            <ol>
              <li>
                当社は、取得した個人情報を必要に応じて共同利用させていただくことがあります。
              </li>
              <li>共同利用を行う項目、範囲等は以下のとおりとします。</li>
            </ol>

            <table className="table2 table-sp-block">
              <tbody>
                <tr>
                  <th>共同して利用する個人情報の項目</th>
                  <td>
                    取得した個人情報のうち、利用目的を遂行するために必要な最小限の情報。
                  </td>
                </tr>
                <tr>
                  <th>共同して利用する者の範囲</th>
                  <td>
                    当社の関連事業部門及び適切な契約を結んだ法人その他の団体等。
                    <br />
                    共同利用する特定の法人名等はそのサービス毎に本人へ通知又は公表します。
                  </td>
                </tr>
                <tr>
                  <th>共同して利用する者の利用目的</th>
                  <td>サービス毎に明示した利用目的のために利用します。</td>
                </tr>
                <tr>
                  <th>
                    共同して利用する個人情報の管理について責任を有する者の名称及び住所並びに法人の場合にあってはその代表者の氏名
                  </th>
                  <td>サービス毎に責任者を定め、本人へ通知又は公表します。</td>
                </tr>
                <tr>
                  <th>取得方法</th>
                  <td>
                    Webサイト上の入力フォーム、契約書、メール、FAX、ハガキ、アンケート、口頭（電話等）、その他書面等による取得。
                  </td>
                </tr>
              </tbody>
            </table>
            <ol start="3">
              <li>
                共同利用を行う場合は、あらかじめ個々のサービス毎に同意の取得、又は本人が容易に知り得る状態に置く等の方法により本人への通知を実施します。
              </li>
            </ol>
          </div>

          <div className="sec_privacy">
            <h4>6.個人情報の取扱いの委託</h4>
            <ol>
              <li>
                当社は、利用目的の達成に必要な範囲内において、取得した個人情報の取扱いの全部又は一部を委託する場合があります。その場合は、個人情報の委託に関する基本契約等の必要な契約を締結し、委託先において情報の適切な取扱い及び管理が図られるよう必要かつ適切な管理・監督を行います。
              </li>
            </ol>
          </div>

          <div className="sec_privacy">
            <h4>7.個人情報提供の任意性</h4>
            <ol>
              <li>
                個人情報の提供は任意ですが、当社が求める個人情報をご提供いただけない場合は、利用目的に沿った当社サービスのご提供その他のご要望にお応えできないことがありますのであらかじめご了承ください。
              </li>
            </ol>
          </div>

          <div className="sec_privacy">
            <h4>8.個人情報の開示・訂正・利用停止について</h4>
            <ol>
              <li>
                開示対象個人情報（個人情報の開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止、利用目的の通知（以下、開示等という）の求めのすべてに応じることができる権限を有する個人情報）について、開示本人からの求めに対して、当社は遅滞なく対応いたします。
              </li>
              <li>
                開示対象個人情報の開示等について当社は、開示対象個人情報についてご本人様から開示等のお申出があった場合、ご本人様の本人確認をおこなった後、開示等をいたします。
              </li>
              <li>
                本人確認について
                当社では、以下のいずれかの方法にて本人確認をさせていただいております。
                <ul>
                  <li>
                    開示本人から既に提供を受けている電話番号にかけ直して、本人を確認する。
                  </li>
                  <li>
                    開示本人から既に提供を受けているEメールアドレスにEメールを送信し、そのEメールに返信して頂くことで本人であることを確認する。
                  </li>
                  <li>
                    手続き時に、氏名と住所、電話番号、生年月日を確認することで、本人を確認する。
                  </li>
                </ul>
              </li>
              <li>
                代理人の本人確認については、代理人に当社まで直接来訪していただき運転免許証又はパスポート、及び委任状をご提示いただいた上で対応させていただきます。
              </li>
              <li>
                開示等の求めに応じる手続きについて当社では、本人確認を行なった後、開示本人に対して「個人情報開示等請求書」を郵送させていただきます。開示本人より「個人情報開示等請求書」を返送いただきましたら、速やかに開示等に対して遅滞なく回答させていただきます。回答方法は、電話による回答又は請求内容への回答を「個人情報開示等回答書」の送付により対応させていただきます。
                なお開示等の請求にあたり、当社では手数料の徴収はいたしません。
              </li>
            </ol>
          </div>

          <div className="sec_privacy">
            <h4>9.アクセス解析ツール</h4>
            <ol>
              <li>
                当サイトでは、Googleによるアクセス解析ツール「Google
                Analytics」を利用しています。
              </li>
              <li>
                Google
                Analyticsは、トラフィックデータの収集のためにCookieを使用しています。
              </li>
              <li>
                収集したトラフィックデータは、サイト利用状況の分析、その他のサービスの提供目的に限りこれを使用します。トラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能は、Cookieを無効にすることで収集を拒否することができますので、お使いのブラウザの設定をご確認ください。
              </li>
              <li>
                詳しくは、以下をご参照ください。
                <br />
                <Link
                  to="https://policies.google.com/technologies/partner-sites"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/technologies/partner-sites
                </Link>
              </li>
            </ol>
          </div>

          <div className="sec_privacy">
            <h4>10.お問合せ先</h4>
            <ol>
              <li>
                当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
              </li>
            </ol>
            <table className="table2 table-sp-block">
              <tbody>
                <tr>
                  <th>名称</th>
                  <td>TANYA UUOO LLC</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>1309 Coffeen Avenue STE 1200，Sheridan, Wyoming 82801</td>
                </tr>
                <tr>
                  <th>個人情報保護管理者</th>
                  <td>川口　聡</td>
                </tr>
                <tr>
                  <th>連絡先</th>
                  <td>
                    TEL : (415) 425-9345
                    <br />
                    MAIL : number@danccy.shop
                  </td>
                </tr>
              </tbody>
            </table>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div style={{ textAlign: "right" }} className="div_privacy_bottom">
              最終更新日：2024年5月24日
            </div>
            <div style={{ textAlign: "right" }} className="div_privacy_bottom">
              株式会社リーピー 代表取締役　
              <br class="pc-none" />
              川口　聡
            </div>
          </div>
        </div>
      </div>

      <div
        className="inner inner-xl"
        style={{
          width: "100%",
          maxWidth: "100%",
          marginBottom: "10%",
          marginTop: "4%",
        }}
      >
        <section
          className="cta_area sm pos_rel contact"
          style={{ paddingBottom: "16%", borderRadius: "12.5vw 12.5vw 0 0" }}
        >
          <div className="inner inner-lg pos_rel">
            <div className="cta_area--txtarea">
              <div className="cta_field_ttl ttl-01 anime typesquare_option active">
                <span className="en font-en">Contact</span>
                <span className="jp typesquare_option">お問い合わせ</span>
              </div>
              <p className="cta_area--catch">
                課題抽出やWebサイト制作の
                <br />
                目的整理からご相談ください
              </p>
              <div className="cta_area--lead mgn-btm40">
                <p></p>
                <p>
                  初めてのサイト制作で何をすればいいかお困りのお客様も歓迎。
                </p>
                <p>
                  現状の課題抽出やサイトの目的の整理、サイトコンセプトの策定からお任せください。もちろん、Web集客の戦略設計を具現化するサイト構成、デザイン、機能面までご提案します。
                </p>
                <p></p>
              </div>
              <p className="tel mgn-btm32">
                058-215-0066
                <small>24時間受付</small>
              </p>
              <ul className="cta_area--btn">
                <li className="btn mgn-btm16">
                  <Link
                    to="/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" typesquare_option"
                    onClick={handleNavigate}
                  >
                    まずは相談する
                    <span className="free typesquare_option">無料</span>
                  </Link>
                </li>
              </ul>
              <ul className="cta_area--btn">
                <li className="btn mgn-btm16">
                  <Link
                    to="/about"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" typesquare_option"
                    style={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    資料請求する
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="cta_area--imgarea">
            <div className="cta_area--slide cta_area--slide1">
              <div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜県"
                    decoding="async"
                    fetchpriority="high"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜県"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="一般社団法人グローバル・メディアネットワーク"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="カンダまちおこし株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="日の丸自動車株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="近江リース株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜商工信用組合"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社アイコットリョーワ"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
              </div>
              <div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜県"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜県"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="一般社団法人グローバル・メディアネットワーク"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="カンダまちおこし株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="日の丸自動車株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="近江リース株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜商工信用組合"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社アイコットリョーワ"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
              </div>
            </div>
            <div className="cta_area--slide cta_area--slide2">
              <div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="大日コンサルタント株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-450x248.jpg 450w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-860x475.jpg 860w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="昭和コンクリート工業株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="医療法人和光会/社会福祉法人和光会"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="山田メディカルクリニック"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="川本鋼材株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社ササキ"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社TKS"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="社会福祉法人舟伏"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
              </div>
              <div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="大日コンサルタント株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-450x248.jpg 450w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-860x475.jpg 860w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="昭和コンクリート工業株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="医療法人和光会/社会福祉法人和光会"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="山田メディカルクリニック"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="川本鋼材株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社ササキ"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社TKS"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="社会福祉法人舟伏"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
