import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    if (scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={`header-container ${scrolled ? "scrolled" : ""}`}>
      <div className="header">
        <div className="header__inner__left">
          <img
            width="224"
            height="54"
            alt=""
            src="https://leapy.jp/wp/wp-content/themes/leapy22/images/common/logo-header.svg"
          />
          <div className="header__inner">
            <div className="wrp-navi">
              <Link to="/" onClick={toTop}>
                ホームページ
              </Link>
              <Link to="/contact" onClick={toTop}>
                お問い合わせ
              </Link>
              <Link to="/privacy" onClick={toTop}>
                プライバシーポリシー
              </Link>
              <Link to="/particular" onClick={toTop}>
                情報セキュリティ方針
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
