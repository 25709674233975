import React, { useState, useEffect } from "react";
import "../css/contact.css";
import { Link } from "react-router-dom";

function About() {
  const [scrolled, setScrolled] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [fullName, setFullName] = useState("");
  const [formData, setFormData] = useState({
    companyName: "",
    email: "",
    tel: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    if (scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (!formData.companyName) errors.companyName = true;
    if (!formData.email) errors.email = true;
    if (!formData.tel) errors.tel = true;

    setFormErrors(errors);
    handleNavigate();

    if (Object.keys(errors).length === 0) {
      setFormData({
        companyName: "",
        fullName: "",
        email: "",
        tel: "",
      });
      alert("送信が成功しました！");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <div className={`header-container ${scrolled ? "scrolled" : ""}`}>
        <div className="header">
          <div className="header__inner__left">
            <img
              width="110"
              height="27"
              alt=""
              src="https://leapy.jp/wp/wp-content/themes/leapy22/images/common/logo-header.svg"
            />
          </div>
        </div>
      </div>
      <div className="contact_main_box">
        <div className="contact_box_left">
          <div id="parts_child_01-column_01">
            <div className="lps_parts--child inner flex flex-col1">
              <div>
                <h1 className="about_h1_txt">
                  <span>
                    【３点セット】リーピーのWebサイト制作・会社概要・サービスがわかる資料
                  </span>
                  <br />
                  <br />
                  <span class="editor-ttl-en2 font-en">e-book</span>
                </h1>
                <div className="single-e-book_content">
                  <div className="single-e-book_editor">
                    <h3>３点セットでわかること</h3>
                    <div className="editor_right_flex">
                      <div className="left_area">
                        <div className="box box-wh">
                          <ul>
                            <li style={{ textAlign: "left" }}>
                              会社概要・支援実績について
                            </li>
                            <li style={{ textAlign: "left" }}>
                              リーピーの支援領域・提供サービス
                            </li>
                            <li style={{ textAlign: "left" }}>
                              リーピーがつくるWebサイトの特長
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-e-book_content">
                  <div className="single-e-book_editor">
                    <h3>過去の支援実績（全国1,150社以上）</h3>
                    <p>
                      <img
                        fetchpriority="high"
                        decoding="async"
                        className="wp-image-43174 size-full alignnone"
                        src="https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo.png"
                        alt="まずは相談する（無料）｜岐阜県でWebサイト制作なら株式会社リーピー"
                        width="810"
                        height="278"
                        srcset="https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo.png 810w, https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo-300x103.png 300w, https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo-780x268.png 780w, https://leapy.jp/wp/wp-content/uploads/2024/10/contact_rogo-768x264.png 768w"
                        sizes="(max-width: 810px) 100vw, 810px"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_box_right">
          <div className="box_right_innerbox">
            <div className="mce-content-body mgn-btm16">
              <h4>資料請求フォーム</h4>
              <div id="formy_form">
                <form
                  id="entry-form-id"
                  name="entry-form"
                  action="https://efo.entry-form.net/form/complete/c311e654b75d4800f5770ffd6c6058f73495558e/"
                  method="post"
                  data-parsley-validate=""
                >
                  <table>
                    <tbody>
                      <tr>
                        <th style={{ justifyContent: "left" }}>
                          <label htmlFor="element_42_5a0e472ba77dd_ml">
                            会社名・屋号
                          </label>
                          <span className="requiredIcon">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="formy_text required formy_timer "
                            id="element_42_5a0e472ba77dd_cp"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            style={{
                              borderColor: formErrors.companyName
                                ? "red"
                                : "#ccc",
                            }}
                            placeholder="例）株式会社リーピー"
                            maxlength="255"
                            parsley-trigger="change keyup keypress focusin focusout"
                            data-required="true"
                          />
                          <br />
                          {formErrors.companyName && (
                            <span style={{ color: "red" }}>
                              会社名を入力してください
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ justifyContent: "left" }}>
                          <label htmlFor="element_42_5a0e472ba77dd_ml">
                            氏名
                          </label>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="formy_text required formy_timer "
                            id="element_42_5a0e472ba77dd_cp"
                            name="fullName"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="例）株式会社リーピー"
                            maxlength="255"
                            parsley-trigger="change keyup keypress focusin focusout"
                            data-required="true"
                          />
                          <br />
                          <span className="help_text">
                            ※フルネームでご記入ください。
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <th style={{ justifyContent: "left" }}>
                          <label htmlFor="element_42_5a0e472ba77dd_ml">
                            メールアドレス
                          </label>
                          <span className="requiredIcon">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="formy_text required formy_timer "
                            id="element_42_5a0e472ba77dd_cp"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            style={{
                              borderColor: formErrors.email ? "red" : "#ccc",
                            }}
                            placeholder="例）株式会社リーピー"
                            maxlength="255"
                            parsley-trigger="change keyup keypress focusin focusout"
                            data-required="true"
                          />
                          <br />
                          <span className="help_text">
                            ※メールにて折り返しご連絡いたします。
                          </span>
                          <br />
                          {formErrors.email && (
                            <span style={{ color: "red" }}>
                              メールアドレスを入力してください
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <th style={{ justifyContent: "left" }}>
                          <label htmlFor="element_42_5a0e472ba77dd_ml">
                            電話番号
                          </label>
                          <span className="requiredIcon">必須</span>
                        </th>
                        <td>
                          <input
                            type="text"
                            className="formy_text required formy_timer "
                            id="element_42_5a0e472ba77dd_cp"
                            name="tel"
                            value={formData.tel}
                            onChange={handleChange}
                            style={{
                              borderColor: formErrors.tel ? "red" : "#ccc",
                            }}
                            maxlength="255"
                            parsley-trigger="change keyup keypress focusin focusout"
                            data-required="true"
                          />
                          <br />

                          <br />
                          {formErrors.tel && (
                            <span style={{ color: "red" }}>
                              メールアドレスを入力してください
                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <button
                  type="submit"
                  className="formy_submit"
                  onClick={handleSubmit}
                >
                  無料相談を申し込む
                </button>
              </div>
              <p className="recaptcha_policy mgn-btm80">
                This site is protected by reCAPTCHA and the Google
                <Link to="/privacy" onClick={handleNavigate}>
                  Privacy Policy
                </Link>
                apply.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
