import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Particular() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_all_box">
        <div className="privacy_box_inner">
          <hgroup className="privacy_first_box">
            <p lang="en">Securitypolicy</p>
            <h1>情報セキュリティ方針</h1>
          </hgroup>
          <div className="privacy_sec_box">
            <div className="contact_about">
              <Link
                className="privacy_contact"
                to="/contact"
                onClick={handleNavigate}
              >
                まずは相談する
                <span
                  className="free typesquare_option"
                  style={{ width: "auto" }}
                >
                  無料
                </span>
              </Link>
              <Link
                className="privacy_more"
                to="/about"
                onClick={handleNavigate}
              >
                資料請求する
              </Link>
            </div>
            <div id="breadcrumbs" className="breadcrumbs txt-sm">
              <span>
                <span>
                  <Link to="/">HOME</Link>
                </span>
                <span className="breadcrumb_last" aria-current="page">
                  情報セキュリティ方針
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="privacy_main_container">
        <div id="section-01" className="section_pdg ">
          <h2>情報セキュリティ方針</h2>
        </div>

        <div className="mce-content-body">
          <p>
            株式会社リーピー（以下、当社）は、当社の情報資産、並びにお客様からお預かりした情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。
          </p>
        </div>

        <div className="mce-content-body">
          <div className="first_privacy">
            <h4>1. 経営者の責任</h4>
            <p>
              当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。
            </p>
          </div>
          <div className="sec_privacy">
            <h4>2. 社内体制の整備</h4>
            <p>
              当社は、情報セキュリティの維持及び改善のために組織を設置し、情報セキュリティ対策を社内の正式な規則として定めます。
            </p>
          </div>
          <div className="sec_privacy">
            <h4>3. 従業員の取組み</h4>
            <p>
              当社の従業員は、情報セキュリティのために必要とされる知識、技術を習得し、情報セキュリティへの取り組みを確かなものにします。
            </p>
          </div>

          <div className="sec_privacy">
            <h4>4. 法令及び契約上の要求事項の遵守</h4>
            <p>
              当社は、情報セキュリティに関わる法令、規制、規範、契約上の義務を遵守するとともに、お客様の期待に応えます。
            </p>
          </div>

          <div className="sec_privacy">
            <h4>5. 違反及び事故への対応</h4>
            <p>
              当社は、情報セキュリティに関わる法令違反、契約違反及び事故が発生した場合には適切に対処し、再発防止に努めます。
            </p>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div style={{ textAlign: "right" }} className="div_privacy_bottom">
              制定日:2020年12月10日
            </div>
            <div style={{ textAlign: "right" }} className="div_privacy_bottom">
              TANYA UUOO LLC
            </div>
            <div style={{ textAlign: "right" }} className="div_privacy_bottom">
              代表取締役　川口聡
            </div>
          </div>
        </div>
      </div>

      <div
        className="inner inner-xl"
        style={{
          width: "100%",
          maxWidth: "100%",
          marginBottom: "10%",
          marginTop: "4%",
        }}
      >
        <section
          className="cta_area sm pos_rel contact"
          style={{ paddingBottom: "16%", borderRadius: "12.5vw 12.5vw 0 0" }}
        >
          <div className="inner inner-lg pos_rel">
            <div className="cta_area--txtarea">
              <div className="cta_field_ttl ttl-01 anime typesquare_option active">
                <span className="en font-en">Contact</span>
                <span className="jp typesquare_option">お問い合わせ</span>
              </div>
              <p className="cta_area--catch">
                課題抽出やWebサイト制作の
                <br />
                目的整理からご相談ください
              </p>
              <div className="cta_area--lead mgn-btm40">
                <p></p>
                <p>
                  初めてのサイト制作で何をすればいいかお困りのお客様も歓迎。
                </p>
                <p>
                  現状の課題抽出やサイトの目的の整理、サイトコンセプトの策定からお任せください。もちろん、Web集客の戦略設計を具現化するサイト構成、デザイン、機能面までご提案します。
                </p>
                <p></p>
              </div>
              <p className="tel mgn-btm32">
                (415) 425-9345
                <small>24時間受付</small>
              </p>
              <ul className="cta_area--btn">
                <li className="btn mgn-btm16">
                  <Link
                    to="/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" typesquare_option"
                    onClick={handleNavigate}
                  >
                    まずは相談する
                    <span className="free typesquare_option">無料</span>
                  </Link>
                </li>
              </ul>
              <ul className="cta_area--btn">
                <li className="btn mgn-btm16">
                  <Link
                    to="/about"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" typesquare_option"
                    style={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    資料請求する
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="cta_area--imgarea">
            <div className="cta_area--slide cta_area--slide1">
              <div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜県"
                    decoding="async"
                    fetchpriority="high"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜県"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="一般社団法人グローバル・メディアネットワーク"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="カンダまちおこし株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="日の丸自動車株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="近江リース株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜商工信用組合"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社アイコットリョーワ"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
              </div>
              <div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜県"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜県"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/04/gifu-bunkasai2024_eyecatch_img-2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="一般社団法人グローバル・メディアネットワーク"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/07/GMN_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="カンダまちおこし株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/kanmachi_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="日の丸自動車株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/05/410ae280e4d21799104fbdf3f4797797.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="近江リース株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/85a8ec6260995230a4234597b6b29254.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="岐阜商工信用組合"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2024/01/shoushin_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社アイコットリョーワ"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2021/06/ic-ryowa_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
              </div>
            </div>
            <div className="cta_area--slide cta_area--slide2">
              <div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="大日コンサルタント株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-450x248.jpg 450w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-860x475.jpg 860w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="昭和コンクリート工業株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="医療法人和光会/社会福祉法人和光会"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="山田メディカルクリニック"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="川本鋼材株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社ササキ"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社TKS"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="社会福祉法人舟伏"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
              </div>
              <div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="大日コンサルタント株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-450x248.jpg 450w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2-860x475.jpg 860w, https://leapy.jp/wp/wp-content/uploads/2022/06/dainichi-consul_eyecatch_img2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="昭和コンクリート工業株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/01/showa-con_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="医療法人和光会/社会福祉法人和光会"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2020/08/wakokai_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="山田メディカルクリニック"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/12/yamada-medical_eyecatch_img-1.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="川本鋼材株式会社"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2022/05/kawamoto-kozai_img-2.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社ササキ"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1536x848.jpg 1536w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/03/sasaki-inc_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="株式会社TKS"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/11/tks_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
                <div className="cta_area--slide-item pos_rel">
                  <img
                    width="780"
                    height="431"
                    src="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg"
                    className="pos_ab img-cover"
                    alt="社会福祉法人舟伏"
                    decoding="async"
                    srcset="https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-780x431.jpg 780w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-300x166.jpg 300w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-768x424.jpg 768w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img-1170x646.jpg 1170w, https://leapy.jp/wp/wp-content/uploads/2023/09/funabuse_eyecatch_img.jpg 1757w"
                    sizes="(max-width: 780px) 100vw, 780px"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Particular;
